<template>
    <div>
        <Icon />
        <Nav />
        <div class="grid-box">
            <v-layout row pa-4 wrap-dash>
                <v-flex md9 lg12 bg-white m-768-r-0>
                    <div class="my-donations-header perfil display-flex">
                        <div>
                            <Icon name="fas fa-credit-card" size="30" class="mr-3" colorFont="#C3C6D5" />
                            <h2 class="mb-0">Meios de Pagamento</h2>
                        </div>
                    </div>
                    <div>
                        <table class="v-datatable v-table theme--light">
                            <thead>
                                <tr>
                                    <th class="text-sm-left">Número</th>
                                    <th class="text-sm-left">Nome</th>
                                    <th class="text-sm-left">Bandeira</th>
                                    <th class="text-sm-left">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in creditCards" :key="item.id">
                                    <td>**** **** **** {{ item.lastDigits }}</td>
                                    <td>{{ item.cardName }}</td>
                                    <td v-if="item.firstDigits[0] == '4'">Visa</td>
                                    <td v-if="item.firstDigits[0] == '5'">Master Card</td>
                                    <td><i v-if="!item.isInUse" @click="inactivateCreditCard(item.id)"><v-icon>cancel</v-icon></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="addNewCard">
                        <v-layout row wrap>
                            <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                            <InputText
                                ref="cardNumberInput"
                                type="text"
                                textLabel="Número do cartão"
                                v-model="creditCard.cardNumber"
                                :disabled="!addNewCard"
                                invalidInputMessage="Preencha o número do cartão"
                                mask="#### #### #### ####"
                            />
                            </v-flex>
                            <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                            <InputText
                                ref="cardHolderNameInput"
                                type="text"
                                textLabel="Nome no cartão"
                                v-model="creditCard.cardName"
                                :disabled="!addNewCard"
                                invalidInputMessage="Preencha o nome"
                            />
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                            <InputText
                                ref="expirationDateInput"
                                type="text"
                                textLabel="Data de vencimento"
                                v-model="creditCard.expirationDate"
                                :disabled="!addNewCard"
                                invalidInputMessage="Preencha a data de vencimento"
                                mask="##/##"
                            />
                            </v-flex>
                            <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                            <InputText
                                ref="cardCVVInput"
                                type="text"
                                textLabel="CVV"
                                v-model="creditCard.cardCVV"
                                :disabled="!addNewCard"
                                invalidInputMessage="Preencha o código de segurança"
                                mask="###"
                            />
                            </v-flex>
                        </v-layout>
                    </div>
                    <v-layout row wrap>
                        <v-btn v-if="addNewCard" flat round color="white" class="btn-primary flex-inherit px-5 mt-3 ml-auto" large @click.prevent="saveCreditCard">
                            Salvar
                        </v-btn>
                        <v-btn v-if="!addNewCard" flat round color="white" class="btn-secondary flex-inherit px-5 mt-3 ml-auto" large @click="clickAddNewCard">
                            Adicionar novo cartão
                        </v-btn>
                    </v-layout>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Icon from "@/components/Icon.vue";
import CreditCard from "@/scripts/models/creditCard.model";
import CartService from "@/scripts/services/cart.service";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";

export default {
  extends: ValidatableComponent,
  components: {
    Nav,
    Icon
  },
  data: () => {
    return {
      cartService: new CartService(),
      creditCard: new CreditCard(),
      addNewCard: false,
      creditCards: [],
    };
  },
  created() {
    this.getCreditCards();
  },
  methods: {
    saveCreditCard() {
      this.cartService.createCreditCard(this.creditCard).then((data) => {
        this.addNewCard = false;
        this.getCreditCards();
      });
    },
    clickAddNewCard() {
      this.creditCard = new CreditCard();
      this.addNewCard = true;
    },
    getCreditCards() {
      this.cartService.getCreditCards().then((data) => {
        this.creditCards = data;
      });
    },
    inactivateCreditCard(cardId){
      this.cartService.inactivateCreditCard(cardId).then(() => {
        this.getCreditCards()
      });
    }
  }
};
</script>

<style>
</style>
